/* ＝＝＝＝＝＝＝＝＝＝＝ Alchemy ＝＝＝＝＝＝＝＝ */

import {MONONOFU_CHARACTERS, getNFTContractList} from './resources/Data.js';
import { getOwnerAddress, setOwnerNFTStatus, isNFTOwner } from './Session';

const { Alchemy, Network } = require("alchemy-sdk");




export const PJOWNER = ['0xf5e9aD5aFA4e7189Dc5A57b3dFE4c35c5b17Bd78'];
export const PJSUPPORTER = [];


// AlchemyのapiKey ETH
const configEth = {
    apiKey: "KX48p8ZwS3j6qK8Tyu2kM1H3xDFt4hkk",
    network: Network.ETH_MAINNET,
};
const alchemyEth = new Alchemy(configEth);

// AlchemyのapiKey POLYGON
const configPoly = {
    apiKey: "DNLn7PGiKFX9WbhJloauqzDsN0HAdHmJ",
    network: Network.MATIC_MAINNET,
};
const alchemyPoly = new Alchemy(configPoly);

const getUserMNFTStatusByChain = async (ownerAddress, chain) => {
	let response = null;

	if(!ownerAddress) {
		return ;
	}

	//Polygon
	if(chain === Network.MATIC_MAINNET) {
		const options = {
            contractAddresses: getNFTContractList(Network.MATIC_MAINNET) // contractAddressesオプションを指定
        };
		response = await alchemyPoly.nft.getNftsForOwner(ownerAddress, options);
	}
	//ETH
  	else {
		const options = {
            contractAddresses: getNFTContractList(Network.ETH_MAINNET) // contractAddressesオプションを指定
        };
		response = await alchemyEth.nft.getNftsForOwner(ownerAddress, options);
	}

	//[mnftid] 1:owner, 0: none の配列
	const ownedNFTList = {};
	if(response.ownedNfts) {
		//「MonoNoFuT_SamuraiPunks_#007v1 #2/10」とかなるのでスペースでsplit
		const ownerNFTTitles = 
			response.ownedNfts.map(userNFT => userNFT.rawMetadata?.name?.split(' ')[0]) //「?」でrawMetadata や name が存在するかを確認
			.filter(title => title !== undefined); // undefined を除外

		MONONOFU_CHARACTERS.forEach(mnft => {
			if(mnft.network !== chain) {
				return; //continue
			}

			ownedNFTList[mnft.nftid] = 0;
			//pjownerだったら全て1
			const isOwner = PJOWNER.some(address => address.toLowerCase() === ownerAddress);
			if(isOwner) {
				//test
				// if(mnft.nftid === '009v2' ) {
				// 	ownedNFTList[mnft.nftid] = 1;
				// }
			}
			else {
				if (ownerNFTTitles.includes(mnft.title)) {
					ownedNFTList[mnft.nftid] = 1;
				}
			}
		});
	}
	return ownedNFTList;
};

export const getAllMNFTStatus = async (userAddress) => {
	const userETHMNFTStatusList		= await getUserMNFTStatusByChain(userAddress, Network.ETH_MAINNET);
	const userMATICMNFTStatusList	= await getUserMNFTStatusByChain(userAddress, Network.MATIC_MAINNET);

	// userETHMNFTStatusListとuserMATICMNFTStatusListをマージして重複を省く
	const mergedNFTStatusList = {};

	// userETHMNFTStatusListをマージ
	for (const key in userETHMNFTStatusList) {
	    if (userETHMNFTStatusList.hasOwnProperty(key)) {
	        mergedNFTStatusList[key] = userETHMNFTStatusList[key];
	    }
	}

	// userMATICMNFTStatusListをマージ
	for (const key in userMATICMNFTStatusList) {
	    if (userMATICMNFTStatusList.hasOwnProperty(key)) {
	        mergedNFTStatusList[key] = userMATICMNFTStatusList[key];
	    }
	}

	setOwnerNFTStatus(mergedNFTStatusList);
};

export const hasPaidNFTs = async (ownerAddress, nftContractAddress, paidTokenIds, chain) => {
	let ownedPaidNFTs = {};

	if(chain === Network.MATIC_MAINNET) {
		const options = {
			contractAddresses: getNFTContractList(Network.MATIC_MAINNET) // contractAddressesオプションを指定
		};
		const responsePoly = await alchemyPoly.nft.getNftsForOwner(ownerAddress, options);
		if(responsePoly.ownedNfts) {
			// paidTokenIds と一致する tokenId を確認
			responsePoly.ownedNfts.forEach((nft) => {
				if (
					nft.contract.address.toLowerCase() === nftContractAddress.toLowerCase() && // contractAddressが一致
					paidTokenIds.includes(nft.tokenId) // tokenIdが一致
				) {
					
					ownedPaidNFTs[nft.tokenId] = true; // 一致したtokenIdにtrueを設定
				}
			});
		}
	}
	else if(chain === Network.ETH_MAINNET) {
		const options = {
			contractAddresses: getNFTContractList(Network.ETH_MAINNET) // contractAddressesオプションを指定
		};
		const responseEth = await alchemyEth.nft.getNftsForOwner(ownerAddress, options);
		if(responseEth.ownedNfts) {
			// paidTokenIds と一致する tokenId を確認
			responseEth.ownedNfts.forEach((nft) => {
				if (
					nft.contract.address.toLowerCase() === nftContractAddress.toLowerCase() && // contractAddressが一致
					paidTokenIds.includes(nft.tokenId) // tokenIdが一致
				) {
					ownedPaidNFTs[nft.tokenId] = true; // 一致したtokenIdにtrueを設定
				}
			});
		}
	}

	//TEST用: pjownerを用いてテスト
	const isOwner = PJOWNER.some(address => address.toLowerCase() === ownerAddress);
	if(isOwner) {
//		ownedPaidNFTs = { '3':true };
		return ownedPaidNFTs;
	}

	return ownedPaidNFTs;
};

export function addrShort(address) {
	if(address === undefined || address === '') {
		return '';
	}

	if (address.length >= 5) {
        var firstFive = address.substring(0, 5);
        var lastFive = address.substring(address.length - 5);

        return firstFive + "..." + lastFive;
    }
    return address;
}

export function getCreatedDateForMetaTag() {
	const date = new Date();
	const year = date.getFullYear();
	const month = date.getMonth() + 1; // Months are zero-indexed
	
	const formattedDate = `${year}.${month}`;
	return formattedDate;
}
