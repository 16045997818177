import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from "react-redux";
import store from "./store/index";
import { hasOwnerData, setOwnerDataSession, getOwnerNFTStatus, getOwnerAddress } from './Session';
import { getAllMNFTStatus, hasPaidNFTs } from './Utils';
import {getNFTByNftId} from './resources/Data.js';
import Button from 'react-bootstrap/Button';

import story from "./css/StoryPage.module.css";
import classes from "./css/HomePage.module.css";
import fontclasses from "./css/NavBar.module.css";
import btn from "./sass/button.scss";

import { useViewportScroll, useTransform, motion, AnimatePresence, useAnimation } from 'framer-motion';

import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

/** 以下ショップ定義 */
const NFTID_TO_SELL = '009v2';
const FREENFT_MINTDATE = '2025/01/06 00:00:00 JST頃 〜 2025/01/10 23:59:59 JST頃';
const PAIDNFT_MINTDATE = '2025/01/11 00:00:00 JST頃 〜 2025/01/15 23:59:59 JST頃';

const FREE_CONDITION_URL =      'https://embed.ipfscdn.io/ipfs/bafybeigdie2yyiazou7grjowoevmuip6akk33nqb55vrpezqdwfssrxyfy/erc1155.html?contract=0x6CC6f8aDC123e7d50406F23c7a22E9DDfD10E7b0&chain=%7B%22name%22%3A%22Polygon+Mainnet%22%2C%22chain%22%3A%22Polygon%22%2C%22rpc%22%3A%5B%22https%3A%2F%2F137.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22POL%22%2C%22symbol%22%3A%22POL%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22matic%22%2C%22chainId%22%3A137%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22polygon%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmd58rKLnBfteouAcmdjQ1HzDvRLSLjMbHjuXRytsKwAkD%22%2C%22width%22%3A360%2C%22height%22%3A360%2C%22format%22%3A%22svg%22%7D%7D&clientId=e7670416ecb1ced493b1ca945acd9422&tokenId=2&theme=dark&primaryColor=purple';
const PAID_CONDITION_URL =      'https://embed.ipfscdn.io/ipfs/bafybeigdie2yyiazou7grjowoevmuip6akk33nqb55vrpezqdwfssrxyfy/erc1155.html?contract=0x6CC6f8aDC123e7d50406F23c7a22E9DDfD10E7b0&chain=%7B%22name%22%3A%22Polygon+Mainnet%22%2C%22chain%22%3A%22Polygon%22%2C%22rpc%22%3A%5B%22https%3A%2F%2F137.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22POL%22%2C%22symbol%22%3A%22POL%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22matic%22%2C%22chainId%22%3A137%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22polygon%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmd58rKLnBfteouAcmdjQ1HzDvRLSLjMbHjuXRytsKwAkD%22%2C%22width%22%3A360%2C%22height%22%3A360%2C%22format%22%3A%22svg%22%7D%7D&clientId=e7670416ecb1ced493b1ca945acd9422&tokenId=3&theme=dark&primaryColor=purple';
const DISCOUNT_CONDITION_URL =  'https://embed.ipfscdn.io/ipfs/bafybeigdie2yyiazou7grjowoevmuip6akk33nqb55vrpezqdwfssrxyfy/erc1155.html?contract=0x6CC6f8aDC123e7d50406F23c7a22E9DDfD10E7b0&chain=%7B%22name%22%3A%22Polygon+Mainnet%22%2C%22chain%22%3A%22Polygon%22%2C%22rpc%22%3A%5B%22https%3A%2F%2F137.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22POL%22%2C%22symbol%22%3A%22POL%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22matic%22%2C%22chainId%22%3A137%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22polygon%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmd58rKLnBfteouAcmdjQ1HzDvRLSLjMbHjuXRytsKwAkD%22%2C%22width%22%3A360%2C%22height%22%3A360%2C%22format%22%3A%22svg%22%7D%7D&clientId=e7670416ecb1ced493b1ca945acd9422&tokenId=4&theme=dark&primaryColor=purple';
const PFP_CONDITION_URL = 'https://embed.ipfscdn.io/ipfs/bafybeigdie2yyiazou7grjowoevmuip6akk33nqb55vrpezqdwfssrxyfy/erc1155.html?contract=0xdd94C027c65Ad2EC292Af59f096FD5FD65043FC1&chain=%7B%22name%22%3A%22Polygon+Mainnet%22%2C%22chain%22%3A%22Polygon%22%2C%22rpc%22%3A%5B%22https%3A%2F%2F137.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22POL%22%2C%22symbol%22%3A%22POL%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22matic%22%2C%22chainId%22%3A137%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22polygon%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmd58rKLnBfteouAcmdjQ1HzDvRLSLjMbHjuXRytsKwAkD%22%2C%22width%22%3A360%2C%22height%22%3A360%2C%22format%22%3A%22svg%22%7D%7D&clientId=e7670416ecb1ced493b1ca945acd9422&tokenId=1&theme=dark&primaryColor=purple';
const EQUIP_CONDITION_URL = 'https://embed.ipfscdn.io/ipfs/bafybeigdie2yyiazou7grjowoevmuip6akk33nqb55vrpezqdwfssrxyfy/erc1155.html?contract=0x0D942EDF6ad5472E8768C2740C4BDbb32EE59F7B&chain=%7B%22name%22%3A%22Polygon+Mainnet%22%2C%22chain%22%3A%22Polygon%22%2C%22rpc%22%3A%5B%22https%3A%2F%2F137.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22POL%22%2C%22symbol%22%3A%22POL%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22matic%22%2C%22chainId%22%3A137%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22polygon%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmd58rKLnBfteouAcmdjQ1HzDvRLSLjMbHjuXRytsKwAkD%22%2C%22width%22%3A360%2C%22height%22%3A360%2C%22format%22%3A%22svg%22%7D%7D&clientId=e7670416ecb1ced493b1ca945acd9422&tokenId=1&theme=dark&primaryColor=purple';

const IS_PAID_MINT = true;
const CHARACTER_DESC = `卓越した外見と頭脳を持つサイボーグ焔。
企業間抗争という容赦ない世界で孤独に戦い続ける彼女。
10年以上経った今も決して消えることなく燻り続けている、ある「想い」──
タングステンのように硬く、冷え切った心の内側に秘められているその想いは、誰にも明かされていない。

—-

Homura, a cyborg with perfect appearance and exceptional intellect.
She has been fighting alone in the merciless world of corporate warfare.
She has the emotion that has been burning quietly in her heart for over a decade and never fading away.
The emotion is sealed firmly behind the thick tungsten door of her heart and never been opened once.`;

/** 
 * ①ユーザーが当該NFTを持っていない場合、本体割引無し（tokenId:1）を表示
 * ②ユーザーがどれか一つでもPJNFTを持っていた場合本体割引あり（tokenId:2）を表示
 * ③当該有料NFTを持っていた（tokenId:[1,2]）場合、PFP、装備品NFTを表示
*/
function ShopPageNew() {
  //ログインを促す用に必要
  const navigate = useNavigate();
  const jump2LoginOnClick = () => {
    navigate('/login');
  };

  //購入した際PFP、装備品のembedを表示させる為にリロードさせる必要がある。
  const change2PFPMintOnClick = async (address) => {
    if(!address) {
      console.log("not logged in");
      return ;
    }
    try {
      // async関数を実行
      await getAllMNFTStatus(address);
      console.log('Async function completed.');

      // ページをリフレッシュ
      navigate('/shopnew');
    } catch (error) {
      console.error('Error in async function:', error);
    }
  };

  //有料NFTの状態を保有
  const [paidNFTs, setPaidNFTs] = useState([]);

  const { t, i18n } = useTranslation();

  const address = getOwnerAddress();
  const dispatch = useDispatch();
  dispatch({type: 'SET_ADDRESS', address: address});


  //当該NFTの情報取得
  const nftobj = getNFTByNftId(NFTID_TO_SELL);

  useEffect(() => {
    const fetchNFTs = async () => {
      if(!address) {
        console.log("not logged in");
        return ;
      }
      
      //当該NFT（有料版）の存在チェック
      const NFTs = await hasPaidNFTs(address, nftobj.contract, nftobj.paidTokenIds, nftobj.network);
      setPaidNFTs(NFTs);
    };

    fetchNFTs();
  }, []);

  //全ステータスが1,0で帰ってくる
  //0以上だとNFT所持
  const ownNFTStatusList = getOwnerNFTStatus();
//  console.log(ownNFTStatusList);
  const ownNFTSum = Object.values(ownNFTStatusList).reduce((acc, value) => acc + value, 0);
//  console.log(ownNFTSum);

  //当該NFT（有料版の保有状況）
  const hasPaidNFT = Object.values(paidNFTs).some((value) => value === true);
//  console.log(hasPaidNFT);


  return (
    <>
    <div id="top" >
      <motion.div
        initial={{ opacity: 0, x: -100 }} // 初期状態（透明で右側にオフセット）
        animate={{ opacity: 1, x: 0 }} // 移動後の状態（デフォルト位置で表示）
        transition={{ duration: 1 }} // アニメーションの時間
      >
      <div className={story.top}>
      <h1>Cyborg NFT Shop</h1>
      <span>Currently On Sale!</span>
      </div>
    </motion.div>
<br />
<main className={classes.content_shop}>
  <motion.div
    initial={{ opacity: 0, x: -100 }} // 初期状態（透明で右側にオフセット）
    animate={{ opacity: 1, x: 0 }} // 移動後の状態（デフォルト位置で表示）
    transition={{ duration: 3 }} // アニメーションの時間
  >

  <section  className={classes.section_shopTitle_left}>
    {/*　有料期間  */}
    {IS_PAID_MINT ?  (
      <section className={classes.section_left}>
        <h2>-= 販売終了 =-</h2>
        <span style={{ fontSize: '2.0em' }}>
          ⛩️フェーズ2（特典付き有料版）<br />
        </span>
        <span style={{ fontSize: '1.0em', color: 'red' }}>期間：{PAIDNFT_MINTDATE}</span> <br />
        <p style={{ fontSize: '0.9em'}}>
        ※ 本プロジェクトNFT保有者の場合、保有者特別20%割引が適応されます。<br />
        ※ 有料版をご購入後、Profile Picture NFTと装備品NFTがそれぞれ1回ずつフリーミント可能です。購入後下記のボタンを押してください。<br />
        ※ アクセスが集中すると購入セクションが一時的に表示されなくなる場合がございます。時間をおいて（最長1日）再度お試しください。<br />
        * NFTの販売に関しての詳細は「<a href="/about">About</a>」ページの「NFTを20%OFFで購入する権利」セクションをご覧ください。
        </p>
        
        {/* 本体 */}
        {ownNFTSum > 0 && !hasPaidNFT ? (
          /* 「NFT保有」だった場合「20%オフ枠」のembedを表示 */
          <>
          {/* <span style={{ fontSize: '1.3em' }}>本体NFT（保有者特別20%割引版）<br /></span>
          <p>
          <Button className={`pulse`} onClick={() => change2PFPMintOnClick(address)}>購入後クリックで特典NFTのフリーミント画面に遷移</Button>
          </p>
          <iframe
              src={DISCOUNT_CONDITION_URL}
              width="100%"
              height="600px"
          ></iframe> */}
          </>
        ) : address && !hasPaidNFT ? (
          /* 「NFT保有してない」場合「通常有料枠」のembedを表示 */
          <>
          <span style={{ fontSize: '1.3em' }}>本体NFT 通常版<br /></span>
          <p>
          <Button className={`pulse`} onClick={() => change2PFPMintOnClick(address)}>購入後クリックで特典NFTのフリーミント画面に遷移</Button>
          </p>
          <iframe
              src={PAID_CONDITION_URL}
              width="100%"
              height="600px"
          ></iframe>
          </>
        ) : !address ? (
          <>
          <p style={{ fontSize: '1.0em', color: 'red' }}>
            ログイン（ウォレットコネクト）してください。<br />
            当PJのNFTを保有していた場合20%の割引が適応されます。<br />
            保有していない場合通常価格での販売になります。
          </p>
          <Button className={`pulse`} onClick={() => jump2LoginOnClick()}>ログイン画面へ遷移</Button>
          </>
        ) : (
          <></>
        )}

        
        {hasPaidNFT && (
          <>
          {/* PFP */}
          <p style={{ fontSize: '1.0em', color: 'red' }}>
          本体ご購入ありがとうございます。<br />
          有料版特典としてPFPと装備品NFTもフリーミント可能です。
          </p>
          <span style={{ fontSize: '1.3em' }}>
          PFP NFT<br />
          </span>
          <iframe
                src={PFP_CONDITION_URL}
                width="100%"
                height="600px"
          ></iframe>

          {/* 装備品 */}
          <span style={{ fontSize: '1.3em' }}>
          装備品 NFT<br />
          </span>
          <iframe
                src={EQUIP_CONDITION_URL}
                width="100%"
                height="600px"
          ></iframe>
          </>
        )}

      </section>

    ) : (

      <section className={classes.section_left}>
      <h2>-= 販売終了 =-</h2>
      <span style={{ fontSize: '2.0em' }}>
        ⛩️フェーズ1（無料版）<br />
      </span>
      <span style={{ fontSize: '1.0em', color: 'red' }}>期間：{FREENFT_MINTDATE}</span> <br />
      <p style={{ fontSize: '0.9em'}}>
      ※ 無料版をミントする場合、Profile Picture NFTと装備品NFTはフリーミントできません。後日有料版をご購入後可能になります。<br />
      ※ アクセスが集中すると購入セクションが一時的に表示されなくなる場合がございます。時間をおいて（最長1日）再度お試しください。<br />
      </p>
      
      {/* 本体 */}
      {/* <span style={{ fontSize: '1.3em' }}>本体NFT（無料版）<br /></span>
      <iframe
          src={FREE_CONDITION_URL}
          width="100%"
          height="600px"
      ></iframe> */}
    </section>
    )}{/* IS_PAID_MINT */}
  </section>
  
  </motion.div>

  <motion.div
    initial={{ opacity: 0, x: 100 }} // 初期状態（透明で右側にオフセット）
    animate={{ opacity: 1, x: 0 }} // 移動後の状態（デフォルト位置で表示）
    transition={{ duration: 2 }} // アニメーションの時間
  >
  <section className={classes.section_shopTitle_right}>
  <section className={classes.section_shopTitle}>
      <h2>{nftobj.name}</h2>
    </section>
  <img className={classes.nftshop} alt="top" src={nftobj.img} />
    
    <section className={classes.section_left}>
    <p style={{ fontSize: '1.0em', whiteSpace: 'pre-line' }}>
    {CHARACTER_DESC}

    </p>
    </section>
  </section>
  </motion.div>
</main>

    </div>
    </>
  );
}

export default ShopPageNew;