import { PJOWNER } from './Utils';
import { NFTTYPE_CHAR, NFTTYPE_PFP, NFTTYPE_EQUIP } from './resources/Data';


const OWNERSESSIONKEY = 'ower_session';
const DEFUALT_DATA = '{"address": "", "isOwner": false, "nftStatusList": "", "region": "ja"}';

function hasOwnerData() {
	const tmpData = sessionStorage.getItem(OWNERSESSIONKEY);
	if(tmpData) {
		return true;
	}
	return false;
}

export function setOwnerDataSession(data) {
	sessionStorage.setItem(OWNERSESSIONKEY, data);
}

export function getOwnerDataSession() {
	const tmp = sessionStorage.getItem(OWNERSESSIONKEY);
	if(tmp) {
		return tmp;
	}
	return DEFUALT_DATA;
}

export function getOwnerAddress() {
	let jsonData = getOwnerDataSession();
	if(jsonData) {
		let ownerDataArray = JSON.parse(jsonData);
		return ownerDataArray['address'];
	}
	return '';
}

export function setOwnerAddress(address) {
	let jsonData = getOwnerDataSession();
	if(jsonData) {
		let ownerDataArray = JSON.parse(jsonData);
		ownerDataArray['address'] = address;
		sessionStorage.setItem(OWNERSESSIONKEY, JSON.stringify(ownerDataArray));
	}
}

export function getOwnerStatus() {
	let jsonData = getOwnerDataSession();
	if(jsonData) {
		let ownerDataArray = JSON.parse(jsonData);
		return ownerDataArray['isOwner'];
	}
	return '';
}

export function setOwnerStatus(status) {
	let jsonData = getOwnerDataSession();
	if(jsonData) {
		let ownerDataArray = JSON.parse(jsonData);
		ownerDataArray['isOwner'] = status;
		sessionStorage.setItem(OWNERSESSIONKEY, JSON.stringify(ownerDataArray));
	}
}

export function getOwnerNFTStatus() {
	let nftStatusList = {};
	const jsonData = getOwnerDataSession();
	let ownerDataArray = JSON.parse(jsonData);
	if(ownerDataArray.nftStatusList) {
		nftStatusList = JSON.parse(ownerDataArray.nftStatusList);
	}

	return nftStatusList;
}

export function setOwnerNFTStatus(nftStatusArray) {
	let jsonData = getOwnerDataSession();
	if(jsonData) {
		//配列をjson形式に
		const ownedNFTListJSON = JSON.stringify(nftStatusArray);

		let ownerDataArray = JSON.parse(jsonData);
		ownerDataArray.nftStatusList = ownedNFTListJSON;
		sessionStorage.setItem(OWNERSESSIONKEY, JSON.stringify(ownerDataArray));
	}
}

export function isNFTOwner() {
	let jsonData = getOwnerDataSession();
	if(jsonData) {
		let ownerDataArray = JSON.parse(jsonData);
		//和を求めて1以上だとtrue
		let nftStatusList = JSON.parse(ownerDataArray['nftStatusList']);
		//和を求める
		const sum = Object.values(nftStatusList).reduce((acc, currentValue) => acc + currentValue, 0);
		return sum > 0;
	}
	return false;
}

export function resetOwnerStatus() {
	setOwnerDataSession(DEFUALT_DATA);
}

export function setRegionSession(lng) {
	let jsonData = getOwnerDataSession();
	if(jsonData) {
		let ownerDataArray = JSON.parse(jsonData);
		ownerDataArray['region'] = lng;
		sessionStorage.setItem(OWNERSESSIONKEY, JSON.stringify(ownerDataArray));
	}
}

export function getRegionSession() {
	let jsonData = getOwnerDataSession();
	if(jsonData) {
		let ownerDataArray = JSON.parse(jsonData);
		return ownerDataArray['region'];
	}
	return 'ja';

}


export const NFT_NOTOWN = -1;
export const NFT_OWN_OTHERNFT = 0;		//ch, pf以外のNFTを持ってる場合
export const NFT_EQUIP_ONLY = 1;		//0001
export const NFT_PFP_ONLY = 2;			//0010
export const NFT_RELATE_ONLY = 4;		//0100
export const NFT_CHAR_ONLY = 8;			//1000

/**
 * 
 * nftに関する保持状況を返す
 * キャラ or 装備品の可能性
 * 
 * @param nft: ch || eq
 */
export function getUserNFTOwningStatus(nft) {
//	console.log("nft.nftid: " + nft.nftid);
	const ownNFTStatusList = getOwnerNFTStatus(); //全NFTのステータスを0か1で返す

	let ownerStatus = NFT_NOTOWN;

	//キャラクターに関連しないNFT所持
	const charAndRelateList = [nft.nftid, ...nft.relate];
	for(const key in ownNFTStatusList) {
		//上記チェックしているものはスキップ
		if(charAndRelateList.hasOwnProperty(key)) {
			continue;
		}

		//キャラ、関連NFTでないNFTを保有している場合
		if(ownNFTStatusList[key] === 1) {
			ownerStatus = NFT_OWN_OTHERNFT;
			//1個でも該当すればbreak
			break;
		}
	}

	// キャラクターNFT or 装備NFTを所持しているか
	if (ownNFTStatusList.hasOwnProperty(nft.nftid) && ownNFTStatusList[nft.nftid] === 1) {
		if(nft.type === NFTTYPE_CHAR) {
			ownerStatus = NFT_CHAR_ONLY;
		}
		else if(nft.type === NFTTYPE_EQUIP) {
			ownerStatus = NFT_EQUIP_ONLY;
		}
	}

	//キャラクターに関連するNFTを所持しているか
	for (const relatedId of nft.relate) {
		if(ownNFTStatusList.hasOwnProperty(relatedId) && ownNFTStatusList[relatedId] === 1) {
			if(ownerStatus === NFT_CHAR_ONLY) {
				ownerStatus = NFT_RELATE_ONLY + NFT_CHAR_ONLY; //1100 (12)
			}
			else {
				ownerStatus = NFT_RELATE_ONLY;
			}
			//1個でも該当すればbreak
			break;
		}
	}

//	console.log('getUserNFTOwningStatus: '+ ownerStatus);
	return ownerStatus;
};
