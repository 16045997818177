import React, { useState, useEffect, useContext, createContext } from 'react';
import { useSelector, useDispatch } from "react-redux";
import store from "./store/index";
import { Navbar, Nav } from 'react-bootstrap';
import { createRoot } from 'react-dom/client';
import { hasOwnerData, setOwnerDataSession, getOwnerDataSession, getOwnerAddress, getOwnerStatus, resetOwnerStatus } from './Session';
import { addrShort } from './Utils';

import classes from "./css/NavBar.module.css";


function NavBar() {
  const address = useSelector((state) => state.address);

  //if logoff, owner data needs to reset
  const dispatch = useDispatch();
  useEffect(() => {
    //windows.ethereumのステータスが変更した場合の処理
    if (typeof window.ethereum !== 'undefined') {
        window.ethereum.on("accountsChanged", (accountNo) => {
          if(accountNo === 'undefined' || accountNo.length == 0) {
            resetOwnerStatus();
            dispatch({type: 'SET_ADDRESS', address: ''});
          }
        });
    }
  }, [address]);

  //表示用addr。長いとモバイル版Webでおかしくなる
  const shortAddr = addrShort(address);

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
      
      <Navbar.Brand className={`${classes.title}`} href="/"><span className={`${classes.electro} ${classes.titlegradient}`}>Project monoNoFuT</span></Navbar.Brand>      
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" className="mr-auto justify-content-right">
        <Nav >
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/story">Story</Nav.Link>
          <Nav.Link href="/characters">Characters</Nav.Link>
{/*          {address ? <Nav.Link href="/wallpaper">WallPaper</Nav.Link> : null} */}
          <Nav.Link href="/shopnew">Shop</Nav.Link>
          <Nav.Link href="/about">About</Nav.Link>
          {(shortAddr) && (
          <Navbar.Text ><span style={{fontSize: '1.0em', marginTop: '-20px'}}>{shortAddr}</span></Navbar.Text>
          )}
          {!address ? <Nav.Link href="/login">Login</Nav.Link> : null}
        </Nav>
      </Navbar.Collapse>
      

    </Navbar>



  );
}


export default NavBar;

